module.exports = [{
      plugin: require('/opt/build/repo/website/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://outicks.com"},
    },{
      plugin: require('/opt/build/repo/website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-133810163-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"cookieDomain":"outicks.com"},
    },{
      plugin: require('/opt/build/repo/website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
