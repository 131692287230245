/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/css/wp-includes/block_library_style.min.css'
import './src/css/wp-content/otter_blocks_all.min.css'
import './src/css/wp-content/otter_blocks_v4-shims.min.css'
import './src/css/wp-content/gutenberg_blocks_style.css'
import './src/css/wp-content/ginger_cookies_enabler_dialog.css'
import './src/css/wp-content/themeisl_companion_clients_bar.css'
import './src/css/wp-includes/dashicons.min.css'
import './src/css/wp-content/themeisle_companion_public.css'
import './src/css/wp-content/elementor_font_awesome.min.css'

import './src/css/hestia/hestia_fonts_googleapis.css'
import './src/css/wp-content/elementor_icons.min.css'
import './src/css/hestia/page_builder_style.css'
import './src/css/wp-content/elementor_post_44.css'
import './src/css/wp-content/google_fonts_1.css'
import './src/css/wp-content/elementor_public.css'

import './src/css/wp-content/elementor_frontend.min.css'
import './src/css/wp-content/elementor_animations.min.css'
import './src/css/hestia/bootstrap.min.css'
import './src/css/hestia/style.min.css'
import './src/css/hestia/style_inline.css'
import './src/css/other_inline_style.css'
import './src/css/hestia/font_sizes.min.css'

